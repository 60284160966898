import React from "react";
import IndexHeader from "../components/Headers/IndexHeader";
import Installations from "../app/works/Installations";

function Index() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });
  return (
    <>
      <IndexHeader />
      <Installations />
    </>
  );
}

export default Index;
