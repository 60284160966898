
import React from "react";

import {
    Container,
    Row,
    Col
} from "reactstrap";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
const position = [45.5366, 9.2774];
function Contact() {
    const { t } = useTranslation();
    return (
        <>
            <Helmet>
                <title>Eugenio dallari - Contatto</title>
                <meta name="description" content="Eugenio dallari , Contattami direttamente nel mio studio , Email: eudallari@hotmail.it" />
                <meta name="keywords" content="dipinti,installazioni,artista,arte,speciale,studio,biografia,virus" />
            </Helmet>
            <div
                className="section-dark text-white"
                style={{"marginTop":"150px"}}
            >
                <Container>
                <Row>
                    <Col>
                        <h3>
                            {t("Contact")}
                        </h3>
                    </Col>
                </Row>
                <Row>
                    <Col md="6">
                        <div className="info">
                            <div className="description">
                                <h1 className="description">
                                    eudallari@hotmail.it
                                </h1>
                            </div>
                        </div>
                    </Col>
                    <Col md="6">
                        <div className="info">
                            <div className="description">
                                <h1 className="description">
                                    +393313153175
                                </h1>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col style={{"height":"300px"}}>
                        <MapContainer center={position} zoom={13} scrollWheelZoom={false} style={{"minHeight":"300px"}}>
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={position}>
                                <Popup>
                                    {t("I am here")}
                                </Popup>
                            </Marker>
                        </MapContainer>
                    </Col>
                </Row>
            </Container>
            </div>
        </>
    );
}

export default Contact;
