import React from "react";
import {Container} from "reactstrap";
import {Helmet} from "react-helmet";

function IndexHeader() {
  return (
    <>
        <Helmet>
            <title>Eugenio dallari - Artista contemporaneo</title>
            <meta name="description" content="Eugenio dallari , classe 1994 artista italiano contemporaneo , Vive e lavora a Milano " />
            <meta name="keywords" content="dipinti,installazioni,artista,arte,speciale,cultura" />
        </Helmet>
      <div
        className="page-header section-dark"
      >
        <div className="filter" >
          <Container>
            <h1 className="presentation-title text-center">
                ART IS EASY
            </h1>
          </Container>
        </div>
      </div>
    </>
  );
}

export default IndexHeader;
