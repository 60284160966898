import React from "react";
import { Row, Container } from "reactstrap";
function DemoFooter() {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          <nav className="footer-nav">
            <a
href="https://www.iubenda.com/termini-e-condizioni/11805238"
               className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe " title="Termini e Condizioni ">Terms&Conditions</a>

            <a
                href="https://www.iubenda.com/privacy-policy/11805238"
                className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe ml-5 " title="Privacy Policy ">Privacy Policy</a>
          </nav>
          <div className="credits ml-auto">
            <span className="copyright">
              © {new Date().getFullYear()}, made with{" "}
              <i className="fa fa-heart heart" />
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;
