import React, {useEffect, useState} from "react";
import classnames from "classnames";
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container
} from "reactstrap";
import {useTranslation} from "react-i18next";
import Headroom from "react-headroom";
import LanguageSwitcher from "./LanguageSwitcher";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-trans");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-trans");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };

  });
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  const { t } = useTranslation();

  if(isMobile){
    return <Navbar className={navbarColor} style={{zIndex:10000000}} expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
              data-placement="bottom"
              href="/"
              title="Eugenio Dallari"
          >
            Eugenio Dallari
          </NavbarBrand>
          <button
              aria-expanded={navbarCollapse}
              className={classnames("navbar-toggler navbar-toggler", {
                toggled: navbarCollapse,
              })}
              onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
            className="justify-content-end"
            navbar
            isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem>
              <NavLink
                  data-placement="bottom"
                  href="https://www.youtube.com/channel/UCWxFj6Ivm-w88LXgGDWohTA"
                  target="_blank"
                  title="Follow me on youtube"
              >
                <i className="fa fa-youtube" />
                <p className="d-lg-none">Youtube</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                  data-placement="bottom"
                  href="https://www.instagram.com/eudallari/"
                  target="_blank"
                  title="Follow me on instagram"
              >
                <i className="fa fa-instagram" />
                <p className="d-lg-none">Instagram</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                  data-placement="bottom"
                  href="https://www.pinterest.it/eudallari/_saved/"
                  target="_blank"
                  title="Follow me on pinterest"
              >
                <i className="fa fa-pinterest" />
                <p className="d-lg-none">Pinterest</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                  href="/bio"
              >
                Bio
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                  href="/installations"
              >
                <i className="nc-icon nc-book-bookmark" />  {t('Installations')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                  href="/paintings"
              >
                {t('Paintings')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                  href="/contact"
              >
                <i className="nc-icon nc-map-big" /> {t('Contact')}
              </NavLink>
            </NavItem>
            <NavItem>
              <LanguageSwitcher/>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  }

  return (
      <>
        <Headroom
            style={{
              boxShadow: '1px 1px 1px rgba(0,0,0,0.25)',
              zIndex:10000000,
              height:'100% !important',
              minHeight:'100px !important',
            }}
        >
          <Navbar className={navbarColor} style={{zIndex:10000000}} expand="lg">
            <Container>
              <div className="navbar-translate">
                <NavbarBrand
                    data-placement="bottom"
                    href="/"
                    title="Eugenio Dallari"
                >
                  Eugenio Dallari
                </NavbarBrand>
                <button
                    aria-expanded={navbarCollapse}
                    className={classnames("navbar-toggler navbar-toggler", {
                      toggled: navbarCollapse,
                    })}
                    onClick={toggleNavbarCollapse}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              <Collapse
                  className="justify-content-end"
                  navbar
                  isOpen={navbarCollapse}
              >
                <Nav navbar>
                  <NavItem>
                    <NavLink
                        data-placement="bottom"
                        href="https://www.youtube.com/channel/UCWxFj6Ivm-w88LXgGDWohTA"
                        target="_blank"
                        title="Follow me on youtube"
                    >
                      <i className="fa fa-youtube" />
                      <p className="d-lg-none">Youtube</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                        data-placement="bottom"
                        href="https://www.instagram.com/eudallari/"
                        target="_blank"
                        title="Follow me on instagram"
                    >
                      <i className="fa fa-instagram" />
                      <p className="d-lg-none">Instagram</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                        data-placement="bottom"
                        href="https://www.pinterest.it/eudallari/_saved/"
                        target="_blank"
                        title="Follow me on pinterest"
                    >
                      <i className="fa fa-pinterest" />
                      <p className="d-lg-none">Pinterest</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                        href="/bio"
                    >
                      Bio
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                        href="/installations"
                    >
                      <i className="nc-icon nc-book-bookmark" />  {t('Installations')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                        href="/paintings"
                    >
                      {t('Paintings')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                        href="/contact"
                    >
                      <i className="nc-icon nc-map-big" /> {t('Contact')}
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
              <LanguageSwitcher/>
            </Container>
          </Navbar>
        </Headroom>
      </>
  );
}

export default IndexNavbar;
