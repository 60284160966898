import React from "react";
import {
    Container,
    Row,
    Col,
} from "reactstrap";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
function Bio() {
    const { t } = useTranslation();
    return (
        <div
            className="section-dark text-white"
            style={{"marginTop":"150px","marginBottom":"200px"}}
        >
            <Helmet>
                <title>Eugenio dallari - Bio</title>
                <meta name="description" content="Eugenio dallari , leggi la biografia e le referenze artistiche di e.c.d bio : A.C.M.E. , Umberto Boccioni , Univesità Statale (MI) , Accademia di Belle Arti di Brera ItalyAmo , Save madness" />
                <meta name="keywords" content="dipinti,installazioni,artista,arte,speciale,studio,biografia,Collective exhibition,Mostra,Mostre" />
            </Helmet>
            <div className="content-center">
                <Container>
                    <Row>
                        <Col>
                            <h2>
                                {t("Biography")}
                            </h2>
                            <h3>
                                <ul>
                                    <li>
                                        {t("Born in")} Segrate 1994 {t("Live and work in")} Cologno Monzese (MI)
                                    </li>
                                </ul>
                            </h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2>
                                {t("Education")}
                            </h2>
                            <h3>
                                <ul>
                                    <li>
                                        2008-2013 l.a.s. U.Boccioni
                                    </li>
                                    <li>
                                        2013-2016 A.C.M.E.
                                    </li>
                                    <li>
                                        2016-2017 Univesità Statale (MI)
                                    </li>
                                    <li>
                                        2017-2020 Accademia di Belle Arti di Brera
                                    </li>
                                </ul>
                            </h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h2>
                                {t("Exhibitions")}
                            </h2>
                            <h3>
                                <ul>
                                <li>
                                    2014 {t("Collective exhibition")} “Art Out”C.s. Cantiere, Milano - {t("curated")} {t("by")} Lodovico Manera
                                </li>
                                <li>
                                    2015  {t("Collective exhibition")} ItalyAmo - Vernissage Just Cavalli Club, Milano - {t("curated")} {t("by")} Giovanna Gioia Passarella
                                </li>
                                <li>
                                    2016  {t("Collective exhibition")} Mostra il Mostro – N.d.a. – {t("curated")} {t("by")} Elisa Gaia Girani  e Francesco Conti(Milano)
                                </li>
                                <li>
                                    2018  la conferenza degli uccelli – artepassante in collab con Dual Band - {t("curated")} {t("by")} Roberta Secchi, Simone Lampis e Cristina Negro
                                </li>
                                <li>
                                    2019 – performer festival della peste, Virus! – fondazione Lazzaretto – {t("curated")} {t("by")} Roberta Secchi, Simone Lampis e Cristina Negro
                                </li>
                                <li>
                                    2020 “stiamo facendo un mondo” – performance nella chiesa di San Carpoforo – {t("curated")} {t("by")} Gabriele di Matteo
                                </li>
                                <li>
                                    2020 Save madness - – fondazione Lazzaretto – {t("curated")} {t("by")} Roberta Secchi, Simone Lampis e Cristina Negro – Online performance.
                                </li>
                                <li>
                                    2021 – Muselmann, {t("tribute")} ad Aldo Carpi – {t("curated")} {t("by")} Stefano Pizzi, Barbara Nahmad e Vittoria Coen – {t("Memorial")} Shoah Milano.
                                </li>
                                <li>
                                    2021 – Neimand is perfect, a tribute to Al Hansen – {t("curated")} {t("by")} Gabriele di Matteo, Bibbe Hansen, Steve Piccolo, Antonio Cioffi, Pau Masclans. – Online performance
                                </li>
                                <li>
                                    2022 “AMOR VACUI” {t("Personal exhibition")} TERMINUS DIGITAL ART; Via pietro custodi 8, Milano - {t("curated")} {t("by")} Pierpaolo ceccarini, Franco Zanetti, Paolo Lagazzi
                                </li>
                            </ul>
                            </h3>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default Bio;
