import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// eslint-disable-next-line import/no-unresolved
import "assets/css/bootstrap.min.css";
// eslint-disable-next-line import/no-unresolved
import "assets/scss/paper-kit.scss?v=1.2.0";
// eslint-disable-next-line import/no-unresolved
import "assets/demo/demo.css?v=1.2.0";
import {Redirect} from "react-router";
import Index from "./views/Index";
import Bio from "./app/bio/Bio";
import Painting from "./app/works/Paiting";
import Installation from "./app/works/Installation";
import Contact from "./app/Contact";

export function Routes(){
    return(
            <BrowserRouter>
                <Switch>
                    <Route path="/bio" render={(props) => <Bio {...props} />} />
                    <Route path="/paintings" render={(props) => <Painting {...props} />} />
                    <Route path="/installations" render={(props) => <Installation {...props} />} />
                    <Route path="/contact" render={(props) => <Contact {...props} />} />
                    <Route path="/" render={(props) => <Index {...props} />} />
                    <Redirect to="/" />
                </Switch>
            </BrowserRouter>
        )
}
