import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import Flag from "react-world-flags";
const LanguageSwitcher =()=> {
    const languageOptions = [
        { value: "it", label: (
                <>
                    <Flag code="it" />
                </>
            )
        },
        {
            value: "en",
            label: (
                <>
                    <Flag code="us" />
                </>
            )
        }
    ];
    const { i18n } = useTranslation();
    const [drop,setDrop]=useState(false);

    const { t } = useTranslation();
    return (
        <Dropdown isOpen={drop} toggle={()=>setDrop(!drop)} >
            <DropdownToggle caret>
                {t('Language')}
            </DropdownToggle>
            <DropdownMenu>
                {languageOptions.map((item) => (
                    <DropdownItem
                        key={item.value}
                        onClick={() => {
                            i18n.changeLanguage(item.value);
                            localStorage.setItem('language', item.value);
                        }}
                        href="#"
                    >
                        {item.label}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
}
export default LanguageSwitcher;
