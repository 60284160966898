import React from "react";
import ReactDOM from "react-dom";
// eslint-disable-next-line import/no-unresolved
import "assets/css/bootstrap.min.css";
// eslint-disable-next-line import/no-unresolved
import "assets/scss/paper-kit.scss?v=1.2.0";
// eslint-disable-next-line import/no-unresolved
import "assets/demo/demo.css?v=1.2.0";
import {CookieBanner} from "@palmabit/react-cookie-law";
import {Routes} from "./Routes";
import IndexNavbar from "./components/Navbars/IndexNavbar";
import DemoFooter from "./components/Footers/DemoFooter";
import "./i18n/i18n";

ReactDOM.render(
    <>
        <CookieBanner
            message="Questo sito fa uso di cookie per migliorare l’esperienza di navigazione degli utenti e per raccogliere informazioni sull’utilizzo del sito stesso. Può conoscere i dettagli consultando la nostra privacy policy qui. Proseguendo nella navigazione si accetta l’uso dei cookie; in caso contrario è possibile abbandonare il sito."
            wholeDomain
            policyLink="https://www.iubenda.com/privacy-policy/11805238"
            onAccept = {() => {}}
            onAcceptPreferences = {() => {}}
            onAcceptStatistics = {() => {}}
            onAcceptMarketing = {() => {}}
        />
      <IndexNavbar />
      <Routes/>
      <DemoFooter/>
    </>,
  document.getElementById("root")
);
