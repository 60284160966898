import FT from "../../assets/img/works/image/painting14.jpg";
import TW from "../../assets/img/works/image/painting12.jpg";


import NT from "../../assets/img/works/image/painting19.jpg";
import ST from "../../assets/img/works/image/painting17.jpg";
import THIRDTY from "../../assets/img/works/image/30.jpg";

export const paintings = [
        {
            "image":"31.jpg",
            "youtubeLink": "https://youtu.be/WtA_Th0QO6M",
            "workType": "painting",
            "material": "Tecnica mista",
            "title": "270 Volti",
            "titleEn": "270 Faces",
            "haiku": "Between the idea\n" +
                "And the reality\n" +
                "Between the motion\n" +
                "And the act\n" +
                "Falls the Shadow",
            "description": "150x70 c.a.",
            "moreDescription": "",
            "productionDate": "2022",
            "place": "Milano",
            "images": [
                {
                    "original": THIRDTY
                },
            ]
        },
        {
            "image":"painting9.jpg",
            "youtubeLink": "",
            "workType": "painting",
            "material": "Acrilico su tela",
            "title": "Paolo Lagazzi e Daniela Tomerini in Vacanza.",
            "titleEn": "Paolo Lagazzi e Daniela Tomerini holydays.",
            "haiku": "",
            "description": "30x20 c.m.",
            "moreDescription": "",
            "productionDate": "2020",
            "place": "",
            "images": [
            ]
        },

        {
            "image":"painting18.jpg",
            "youtubeLink": "",
            "workType": "painting",
            "material": "Acrilico su tela",
            "title": "Il laboratorio teatrale (Roberta Secchi, Cristina Negro, Simone Lampis).",
            "titleEn": "theatre laboratory (Roberta Secchi, Cristina Negro, Simone Lampis)",
            "haiku": "",
            "description": "40x30 c.m.",
            "moreDescription": "",
            "productionDate": "2020",
            "place": "",
            "images": [

            ]
        },
        {
            "image":"painting8.jpg",
            "youtubeLink": "",
            "workType": "painting",
            "material": "Acrilico su tela",
            "title": "Vagone",
            "titleEn": "Vagon",
            "haiku": "",
            "description": "120x70 c.m.",
            "moreDescription": "",
            "productionDate": "2017",
            "place": "",
            "images": [

            ]
        },
        {
            "image":"painting7.jpg",
            "youtubeLink": "",
            "workType": "painting",
            "material": "Acrilico su tela",
            "title": "Sdraiati a letto",
            "titleEn": "Laying in bed",
            "haiku": "",
            "description": "100x80 c.m.",
            "moreDescription": "",
            "productionDate": "2016",
            "place": "",
            "images": [

            ]
        },
        {
            "image":"painting6.jpg",
            "youtubeLink": "",
            "workType": "painting",
            "material": "Acrilico su tela",
            "title": "Cascata",
            "titleEn": "Fall",
            "haiku": "",
            "description": "120x80 c.m.",
            "moreDescription": "",
            "productionDate": "2015",
            "place": "",
            "images": [

            ]
        },
        {
            "image":"painting13.jpg",
            "youtubeLink": "",
            "workType": "painting",
            "material": "Acrilico su tela",
            "title": "Campo",
            "titleEn": "Camp",
            "haiku": "",
            "description": "40x30 c.m.",
            "moreDescription": "",
            "productionDate": "2015",
            "place": "",
            "images": [

            ]
        },
        {
            "image":"painting4.jpg",
            "youtubeLink": "",
            "workType": "painting",
            "material": "Acrilico su tela",
            "title": "Festa",
            "titleEn": "Party",
            "haiku": "",
            "description": "120x80 c.m.",
            "moreDescription": "",
            "productionDate": "2013",
            "place": "",
            "images": [

            ]
        },
        {
            "image":"painting2.jpg",
            "youtubeLink": "",
            "workType": "painting",
            "material": "Acrilico su tela",
            "title": "Pattern (Mescolanza ottica)",
            "titleEn": "Pattern",
            "haiku": "",
            "description": "50x50 c.m.",
            "moreDescription": "",
            "productionDate": "2013",
            "place": "",
            "images": [

            ]
        },
        {
            "image":"painting1.jpg",
            "youtubeLink": "",
            "workType": "painting",
            "material": "Acrilico su tela",
            "title": "Ponte",
            "titleEn": "Bridge",
            "haiku": "",
            "description": "100x80 c.m.",
            "moreDescription": "",
            "productionDate": "2012",
            "place": "",
            "images": [

            ]
        },
        {
            "image":"painting15.jpg",
            "youtubeLink": "",
            "workType": "painting",
            "material": "Acrilico su tela",
            "title": "Vorrei profumasse",
            "titleEn": "I want it to smell like roses",
            "haiku": "",
            "description": "100x80 c.m.",
            "moreDescription": "",
            "productionDate": "2012",
            "place": "",
            "images": [
                {
                    "original":  NT
                },
                {
                    "original":  ST
                },
            ]
        },
        {
            "image":"painting16.jpg",
            "youtubeLink": "",
            "workType": "painting",
            "material": "Acrilico su tela",
            "title": "Ritratto",
            "titleEn": "Portrait",
            "haiku": "",
            "description": "30x40 c.m.",
            "moreDescription": "",
            "productionDate": "2011",
            "place": "",
            "images": [

            ]
        },
        {
            "image":"painting3.jpg",
            "youtubeLink": "",
            "workType": "painting",
            "material": "Acrilico su tela",
            "title": "Strada",
            "titleEn": "The Way",
            "haiku": "",
            "description": "45x30 c.m.",
            "moreDescription": "",
            "productionDate": "2010",
            "place": "",
            "images": [

            ]
        },
        {
            "image":"painting11.jpg",
            "youtubeLink": "",
            "workType": "painting",
            "material": "Squash su carta",
            "title": "Ritratto",
            "titleEn": "Portrait",
            "haiku": "",
            "description": "70x50 c.m.",
            "moreDescription": "",
            "productionDate": "2010",
            "place": "",
            "images": [
                {
                    "original":  FT
                },
                {
                    "original":  TW
                },
            ]
        }

    ];

