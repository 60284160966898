
import React from "react";

import {
    Card,
    Container,
    Row,
    Col, CardBody, CardTitle, CardText, CardFooter
} from "reactstrap";
import {useTranslation} from "react-i18next";
import ImageGallery from "react-image-gallery";
import {Helmet} from "react-helmet";
import {paintings} from "./PaintingData";

function Painting() {
    const { t,i18n } = useTranslation();
    return (
        <>
            <div
                className="section-dark text-white"
                style={{"marginTop":"150px","marginBottom":"150px"}}
            >
                <Helmet>
                    <title>Eugenio dallari - Dipinti</title>
                    <meta name="description" content="Eugenio dallari , i miei dipinti e le mie opere" />
                    <meta name="keywords" content="dipinti,installazioni,artista,arte,speciale,studio,biografia,virus" />
                </Helmet>
                <Container>
                    {
                        paintings.map((item) =>
                            <Row>
                                <Col>
                                    <Card>
                                        <img width="100%" src={require(`assets/img/works/image/${item.image}`).default} alt="" />
                                        <CardBody>
                                            <CardTitle tag="h5">
                                                {i18n.language==="it"?item.title:item.titleEn}
                                            </CardTitle>
                                            <CardText>{t(item.material)} {item.description} {item.productionDate}</CardText>
                                        </CardBody>
                                        {
                                            item.images && item.images.length===1 &&
                                            (
                                                <CardFooter>
                                                    <img width="100%" src={item.images[0].original} alt="" />
                                                </CardFooter>
                                            )
                                        }
                                        {
                                            item.images && item.images.length>1 &&
                                            (
                                                <CardFooter>
                                                    <ImageGallery items={item.images} />
                                                </CardFooter>
                                            )
                                        }
                                    </Card>
                                </Col>
                            </Row>
                        )
                    }
                </Container>
            </div>
        </>
    );
}

export default Painting;
