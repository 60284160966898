// eslint-disable-next-line import/no-duplicates
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// eslint-disable-next-line no-unused-vars
import Backend from 'i18next-http-backend';
// eslint-disable-next-line no-unused-vars
import LanguageDetector from 'i18next-browser-languagedetector';
// eslint-disable-next-line import/no-duplicates
import i18next from "i18next";

const resources = {
    en: {
        translation: {
            Works: "Works",
            "Acrilico su tela":"Acrylic on canvas",
            "Acrilico su carta":"Acrylic on paper",
        },
    },
    it: {
        translation: {
            Works: "Lavori",
            Language: "Lingua",
            Contact: "Contatti",
            Paintings: "Dipinti",
            Installations: "Installazioni",
            Exhibitions:"Mostre",
            Contacts:"Contatti",
            "I am here":"Sono qui",
            Education:"Percorso formativo",
            "Acrilico su tela":"Acrilico su tela",
            Biography:"Biografia",
            "curated":"a cura",
            by:"di",
            "Link online art show":"Link mostra online",
            "Born in":"Nato a",
            "Live and work in":"Vive e lavora a",
            "Collective exhibition":"Mostra collettiva",
            "Personal exhibition":"Mostra personale",
        },
    },
};

i18next
    .use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem("language") ? localStorage.getItem("language"):"it",
        interpolation: {
            escapeValue: false,
        },
    });
/*
i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en',
        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });

*/



export default i18n;
