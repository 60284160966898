import React from "react";
import {Helmet} from "react-helmet";
import Installations from "./Installations";

function Installation() {
    return (
        <>
            <Helmet>
                <title>Eugenio dallari - Installazioni</title>
                <meta name="description" content="Eugenio dallari , lei mie installazioni artistiche , guarda le mie installazioni multimediali" />
                <meta name="keywords" content="dipinti,installazioni,artista,arte,speciale,studio,biografia,virus" />
            </Helmet>
            <Installations/>
        </>
    );
}

export default Installation;
