import React from "react";
import {
    Card,
    Container,
    Row,
    Col,
    CardBody, CardTitle, CardText, CardLink, ModalHeader, ModalBody, ModalFooter, Button, Modal as Modals, CardFooter
} from "reactstrap";
import YouTube from "react-youtube";
import {useTranslation} from "react-i18next";
import { ReactVideo } from 'reactjs-media';
import ImageGallery from "react-image-gallery";
import {works} from "./WorkData";

function Installations() {
    const [modal, setModal] = React.useState(false);
    const [modalVideo, setModalVideo] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState(false);
    const opts = {
        height: '600',
        width: '100%',
        minHeight: '100%',
        playerVars: {
            autoplay: 1,
        },
    };
    const setSelectedYoutube=(item)=>{
        setSelectedItem(item);
        setModal(!modal);
    }

    const setSelectedVideo=(item)=>{
        setSelectedItem(item);
        setModalVideo(!modalVideo);
    }
    const toggle = () => setModal(!modal);

    const toggleVideo = () => setModalVideo(!modalVideo);


    const { i18n,t } = useTranslation();
    return (
        <>
            <div
                className="section-dark text-white"
                style={{"marginTop":"150px","marginBottom":"150px"}}
            >
                <Container>
                    {
                        works.map((item,i) =>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <CardTitle tag="h5">
                                            </CardTitle>
                                        </CardBody>
                                        <img width="100%" src={require(`assets/img/works/image/${item.image}`).default} alt="" />
                                        <CardBody>
                                            <CardTitle tag="h3" className="mt-2">
                                                {i18n.language==="it"?item.title:item.titleEn} -
                                                {
                                                    item.material && item.material!=="" &&
                                                    (
                                                        <>  {i18n.language==="it"?item.material:item.materialEn}</>
                                                    )
                                                }
                                                - {item.productionDate}
                                            </CardTitle>
                                            {
                                                item.youtubeLink && item.youtubeLink!=="" &&
                                                (
                                                    <CardLink href="#" onClick={()=>setSelectedYoutube(item)}>
                                                        <Button className="btn btn-default mt-5">
                                                            <i className="fa fa-youtube"/> Video
                                                        </Button>
                                                    </CardLink>
                                                )
                                            }
                                            {
                                                item.link &&
                                                (
                                                    <Row>
                                                        <Col>
                                                            <a className="btn btn-default mt-5" target="_blank" href={item.link}> {t("Link online art show")}</a>
                                                        </Col>
                                                    </Row>
                                                )
                                            }
                                            {
                                                !item.youtubeLink && item.video &&
                                                (
                                                    <CardLink href="#" onClick={()=>setSelectedVideo(item)}>
                                                        <Button className="btn btn-default mt-5">
                                                            <i className="fa fa-eye"/> Video
                                                        </Button>
                                                    </CardLink>
                                                )
                                            }
                                            <CardText
                                                className="float-right text-secondary text-haiku mt-5" style={{
                                                "whiteSpace": "pre-line",
                                                "verticalAlign": "bottom"}} >
                                                <h5 className="font-italic font-weight-lighter">
                                                    “{i18n.language==="it"?item.haiku:item.haikuEn}“
                                                </h5>
                                                {
                                                    item.tribute &&
                                                    (
                                                        <h5 className="font-italic">
                                                            {item.tribute}
                                                        </h5>
                                                    )
                                                }
                                            </CardText>
                                            <CardText style={item.marginTop ?{"marginTop":`${item.marginTop}px`}:{"marginTop":"100px"}}>
                                                <h5 className="font-weight-bold">{i18n.language==="it"?item.subtitle:item.subtitleEn}</h5>
                                                <h5 className="mt-2">{i18n.language==="it"?item.description:item.descriptionEn}</h5>
                                            </CardText>
                                        </CardBody>
                                        {
                                            item.images && item.images.length===1 && (
                                                <CardFooter>
                                                    <img width="100%" src={item.images[0].original} alt="" />
                                                </CardFooter>
                                            )
                                        }
                                        {
                                            item.images && item.images.length>1 &&
                                            (
                                                <CardFooter>
                                                    <ImageGallery items={item.images} />
                                                </CardFooter>
                                            )
                                        }
                                    </Card>
                                </Col>
                            </Row>
                        )

                    }

                    <Modals isOpen={modal} toggle={toggle} className="w-100 modal-xl modal-content-bigger" >
                        <ModalHeader toggle={toggle}>{i18n.language==="it"?selectedItem.title:selectedItem.titleEn}</ModalHeader>
                        <ModalBody>
                            <YouTube videoId={selectedItem.movieId} opts={opts} />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggle}>Close</Button>
                        </ModalFooter>
                    </Modals>
                    <Modals isOpen={modalVideo} toggle={toggleVideo} className="w-100 modal-xl modal-content-bigger" >
                        <ModalHeader toggle={toggleVideo}>{i18n.language==="it"?selectedItem.title:selectedItem.titleEn}</ModalHeader>
                        <ModalBody>
                            <ReactVideo
                                src={selectedItem && selectedItem.video}
                                poster={selectedItem && require(`assets/img/works/image/${selectedItem.image}`).default}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggleVideo}>Close</Button>
                        </ModalFooter>
                    </Modals>
                </Container>
            </div>
        </>
    );
}

export default Installations;
